//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
import { FaSpinner as SpinnerIcon } from "react-icons/fa";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Button extends React.Component {
  render() {
    const { className, children, disabled, loading, onClick } = this.props;

    const componentClasses = classNames(styles.button, {
      [styles[`button--disabled`]]: loading || disabled,
      [className]: className,
    });

    return (
      <button type="submit" className={componentClasses} onClick={onClick}>
        {loading ? (
          <SpinnerIcon className={styles.button__loading_indicator} />
        ) : (
          <span className={styles.button__span}>{children}</span>
        )}
      </button>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Button;
