//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { PlotType } from "@helpers/map";
//------------------------------------------------------------------------------
// Class -----------------------------------------------------------------------
class PlotBounds {
  constructor() {
    this.mapBounds = new window.google.maps.LatLngBounds();
  }

  expandBy(plot) {
    switch (plot.plotType) {
      case PlotType.MARKER:
        const {
          options: { position },
        } = plot;
        this.mapBounds.extend(position);
        break;

      case PlotType.POLYLINE:
        break;

      default:
        break;
    }
  }

  latLngBounds() {
    return this.mapBounds;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PlotBounds;
