//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { StaticAsset } from "@helpers/assets";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { CargoBreakdown } from "@cmp/common";
import { InfoSection, InfoText } from "../../common";
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { formatDate } from "@helpers/formatter";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class PortInfo extends React.Component {
  detailsComponent() {
    const { object: port } = this.props;
    if (!port) return "";

    const photoUrl = StaticAsset.Port(port);

    return (
      <div className={styles.port_info__details}>
        <h1 className={styles.details__name}>{port.portName}</h1>
        <div className={styles.details__extra}>
          {photoUrl && (
            <img
              src={photoUrl}
              className={styles.details__image}
              alt={`${port.name} port`}
            />
          )}
        </div>
      </div>
    );
  }

  destinationComponent(fullVessel, vesselInfo) {
    if (!fullVessel || !vesselInfo) return "";

    return (
      <div className={styles.port_info__destination}>
        <header className={styles.destination__header}>
          <h1 className={styles.destination__title}>{fullVessel.name}</h1>
          {vesselInfo.cars && (
            <span className={styles.destination__total}>
              {vesselInfo.cars.total}
            </span>
          )}
        </header>
        <div className={styles.details__text}>
          <InfoText
            title="ETA"
            description={formatDate.shortDate(vesselInfo.arrivalDate)}
          />
          <InfoText
            title="Wind"
            description={`${fullVessel.wind.speed} knots`}
          />
        </div>
      </div>
    );
  }

  modelsComponent(breakdown) {
    if (!breakdown) return "";
    return <CargoBreakdown breakdown={breakdown} />;
  }

  render() {
    const { className, object: port, vessels } = this.props;
    if (!port) return "";

    const idealVessel = port.vessels && port.vessels[0];
    const fullVessel =
      idealVessel && vessels.find((x) => x.imo === idealVessel.imo);

    const componentClasses = classNames(styles.port_info, {
      [className]: className,
    });

    const vesselCargoBreakdown =
      idealVessel && idealVessel.cars && idealVessel.cars.breakdown;
    const isValidVesselCargoBreakdown =
      vesselCargoBreakdown && vesselCargoBreakdown.length > 0;

    const portCargoBreakdown = port && port.cars && port.cars.breakdown;
    const isValidPortCargoBreakdown =
      portCargoBreakdown && portCargoBreakdown.length > 0;

    return (
      <div className={componentClasses}>
        <InfoSection title="Port" className={styles.port_info__section}>
          {this.detailsComponent()}
        </InfoSection>

        {idealVessel && fullVessel && (
          <>
            <InfoSection
              title="Next Vessel"
              className={styles.port_info__section}
            >
              {this.destinationComponent(fullVessel, idealVessel)}
            </InfoSection>
          </>
        )}

        {(isValidVesselCargoBreakdown || isValidPortCargoBreakdown) && (
          <InfoSection
            title={
              isValidVesselCargoBreakdown
                ? "Expected Models"
                : "Production Breakdown"
            }
            className={classNames(
              styles.port_info__section,
              styles[`port_info__section--large`]
            )}
          >
            {this.modelsComponent(
              isValidVesselCargoBreakdown
                ? vesselCargoBreakdown
                : portCargoBreakdown
            )}
          </InfoSection>
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default PortInfo;
