//------------------------------------------------------------------------------
// Node Modules ---------------------------------------------------------
import { defaultClient } from "@api/client";
//------------------------------------------------------------------------------
// Requests --------------------------------------------------------------------
export const signIn = ({ email, password }) => {
  return defaultClient.post("/token", { email, password });
};

export const token = ({ apikey: apiToken }) => {
  return defaultClient.post("/token", { apiToken });
};
