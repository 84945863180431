//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { CargoBreakdown } from "@cmp/common";
import { InfoSection, InfoText } from "../../common";
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { formatDate } from "@helpers/formatter";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class VesselInfo extends React.Component {
  detailsComponent() {
    const { object: vessel } = this.props;

    return (
      <div className={styles.vessel_info__details}>
        {vessel.photoUrl && (
          <img
            src={vessel.photoUrl}
            className={styles.details__image}
            alt={`${vessel.name} vessel`}
          />
        )}
        <div className={styles.details__extra}>
          <h1 className={styles.details__name}>{vessel.name}</h1>
          <div className={styles.details__text}>
            <InfoText
              title="Speed"
              direction="row"
              description={`${vessel.speed} knots`}
            />
            <InfoText
              title="Course"
              direction="row"
              description={`${vessel.courseDirection}º`}
            />
            <InfoText
              title="Wind"
              direction="row"
              description={`${vessel.wind.speed} knots`}
            />
          </div>
        </div>
      </div>
    );
  }

  destinationComponent() {
    const { object: vessel } = this.props;

    const { nextDestination } = vessel;
    if (!nextDestination) return "";

    const expectedCargo = nextDestination.cargo && nextDestination.cargo.total;

    return (
      <div className={styles.vessel_info__destination}>
        <header className={styles.destination__header}>
          <h1 className={styles.destination__title}>{nextDestination.name}</h1>
        </header>
        <div className={styles.details__text}>
          <InfoText
            title="ETA"
            description={formatDate.shortDate(nextDestination.eta)}
          />
          {expectedCargo ? (
            <InfoText title="Expected Cargo" description={expectedCargo} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }

  modelsComponent() {
    const { object: vessel } = this.props;
    if (!vessel) return "";

    const { nextDestination: { cargo } = {} } = vessel;

    return <CargoBreakdown breakdown={cargo && cargo.breakdown} />;
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.vessel_info, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <InfoSection
          title="Vessel"
          className={classNames(styles.vessel_info__section)}
        >
          {this.detailsComponent()}
        </InfoSection>

        <InfoSection
          title="Next Destination"
          className={classNames(styles.vessel_info__section)}
        >
          {this.destinationComponent()}
        </InfoSection>

        <InfoSection
          title="Expected Models"
          className={classNames(
            styles.vessel_info__section,
            styles[`vessel_info__section--large`]
          )}
        >
          {this.modelsComponent()}
        </InfoSection>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default VesselInfo;
