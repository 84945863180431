export const vehiclesValidator = {
  isSearchResultValid: (searchQuery, searchResult) => {
    // const { setCurrentItem, updateSearchStatus } = this.props
    // const len = query.length
    // const regex = query.match(/([A-Z])(\d{5})/g) !== null
    // const validCommNum = len === 6 && regex
    // const validVinNum = len === 17
    // const valideQuery = validVinNum || validCommNum
    // const vehicleFound = search.length !== 0
    // const activeUserSearch = Array.from(search)[0] !== 'empty'
    // // const outOfScope = this.props.searchStatus === 'out of application scope'
    // const validatedResults = !valideQuery ? 'invalid search query' : (!vehicleFound && activeUserSearch ? "vehicle not found" : search)
    // updateSearchStatus(validatedResults)
    // const { searchStatus, updateVesselDetailsStatus, updatePortDetailsStatus } = this.props
    // const closeAllViews = () => {
    //     updateVesselDetailsStatus(false)
    //     updatePortDetailsStatus(false)
    //     updateFactoryDetailsStatus(false)
    //     setCurrentItem({})
    // }
    // // the user search is valid. if the car model exists, proceed to the next step. otherwise tell the user the model is out of the scope of this application
    // if (typeof validatedResults !== 'string' && searchStatus !== 'empty' && searchStatus[0].stationId !== 0) {
    //     this.handleVehicleLocation(searchStatus[0])
    // } else if (typeof searchStatus === 'string' /*&& validatedResults !== 'out of application scope'*/) {
    //     updateSearchStatus(validatedResults)
    //     closeAllViews()
    // } else {
    //     updateSearchStatus('out of application scope')
    //     closeAllViews()
    // }

    if (searchResult.length <= 0) return { error: "Vehicle not found" };

    const commissionNumberRegularExpression = /^([A-Z]{1})(\d{5})$/g;
    const isValidCommissionNumber =
      searchQuery.match(commissionNumberRegularExpression) !== null;
    const isValidVIN = searchQuery.length === 17;

    const isValidQuery = isValidCommissionNumber || isValidVIN;
    if (!isValidQuery) return { error: "Invalid search query" };

    return { data: searchResult[0] };
  },
};
