//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------

//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { EmdenPort, InoperativePorts } from "@helpers/constants/ports";
import { ObjectType } from "@helpers/constants/object";
import {
  Model as PoscheModel,
  ModelOrder as PorscheModelOrder,
} from "@helpers/constants/models";
import { PlotType, PolylineType } from "@helpers/map";
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export const formatData = {
  fromSchedule: (schedule, ports) => {
    if (!schedule || !ports) return;

    // Updating the naming to “vessels” so that we always use this convention
    // when dealing with anything that's a floating platform that can be
    // steered.
    // The API uses both “ship” and “vessel” and it can be quite confusing
    // sometimes. The front-end will always use “vessel”.
    const { ships: vessels } = schedule;
    if (!vessels || vessels.length <= 0) return;

    // This can also be achieved using a hash table (also improving
    // performance), or by reducing the vessels.
    // I've decided to implement this in a straightforward way so that:
    // 1. It's easy to understand what's going on,
    // 2. It's easy to debug any issues,
    // 3. And it's easy to iterate over it in the future.
    //
    // Consider upgrading this before production release!

    // We're keeping track of both vessels and ports separately:
    // - allVessels will contain the detailed information of a vessel, such as
    //   their name, photo, course direction and tracks.
    // - A port is essentially a vessel stop -- and it might contain additional
    //   information of a vessel specific to that stop, such as arrival date,
    //   cargo data etc.
    // It's imperative that components match the two sources by using the IMO in
    // order to show meaningful information.
    let allVessels = [];
    let allPorts = [];

    // Go through every vessel available in the schedule
    vessels.forEach((vessel) => {
      const currentVesselIMO = vessel.imo;

      // Although we're going through each port down below, the idea here is to
      // separate the code so that when creating the formatted vessel we have
      // access to the next destination. TO-DO: Improve this.

      const sortedPortList = vessel.portlist;
      sortedPortList.sort((a, b) => new Date(a.eta) - new Date(b.eta));

      // The `find` method, as described in MDN, will return the value of the
      // first element that matches the testing function. We've already sorted
      // them, so it's just a matter of comparing with the current date.
      // More info: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
      let nextDestination = sortedPortList.find(
        (x) => new Date(x.eta) > new Date()
      );

      if (!nextDestination) {
        // The old front-end code will always present a “next destination”, even
        // if there's none. Here we're getting the last port from the sorted
        // list.
        // We can easily remove these lines and nothing will break -- just the
        // middle component of the bottom container will disappear.
        nextDestination = sortedPortList[sortedPortList.length - 1];
      }

      const formattedVessel = {
        name: vessel.name,
        courseDirection: vessel.direction,
        imo: currentVesselIMO,
        speed: vessel.speed,
        photoUrl: vessel.photo,
        wind: {
          direction: vessel.winddirection,
          speed: vessel.windspeed,
        },
        projected: vessel.projected,
        location: vessel.location,
        tracks: vessel.tracks,
        nextDestination: {
          name: nextDestination.port,
          cargo: formatCars.cargoTotal(nextDestination.cargo),
          eta: new Date(nextDestination.eta),
        },
      };

      allVessels.push(formattedVessel);

      // Get the formatted departure date
      const formattedDepartureDate = Date.parse(vessel.departuredate);

      // Go through every port list in the vessel data
      sortedPortList.forEach((currentPort) => {
        // Get its name (changing the variable name so it's easier to understand
        // it down below)
        const portName = currentPort.port;
        const isEmden = portName === EmdenPort.name;

        // Get the formatted arrival date
        const formattedArrivalTime = Date.parse(currentPort.eta);

        // Check if the port already exists in `allPorts`
        const portIndex = allPorts.findIndex((x) => x.portName === portName);

        let port;

        if (portIndex > -1) {
          // If the port exists, use it.
          port = allPorts[portIndex];
        } else {
          port = { portName };
        }

        // Append the vessel to its `vessels` array, but first making sure that
        // it exists.
        let vessels = port.vessels || [];

        const formattedCargo = formatCars.cargoTotal(currentPort.cargo);

        if (formattedCargo && formattedCargo.total > 0) {
          vessels.push({
            imo: currentVesselIMO,
            departureDate: formattedDepartureDate,
            arrivalDate: formattedArrivalTime,
            cars: formattedCargo,
          });
        }

        port.vessels = vessels;

        port.center = {
          lat: parseFloat(currentPort.location[0]),
          lng: parseFloat(currentPort.location[1]),
        };

        // Emden is treated as a port and a factory. What we're doing here is
        // attaching cargo information to the `port` object, which doesn't
        // really need to happen for other ports; checking if it's Emden so that
        // we don't run `find` for all of them unnecessarily.
        if (isEmden) {
          const fullPortInformation = ports.find((x) => x.name === portName);
          port.cars = formatCars.cargoTotal(fullPortInformation.cars);
          // port.portName = EmdenPort.formattedName;
        }

        // If we've already accounted the port, update the array. Or simply add
        // the new instance if the port is “operational” (also known as a
        // “Porsche port” from the business perspective).
        if (portIndex > -1) {
          allPorts[portIndex] = port;
        } else if (InoperativePorts.indexOf(port.portName) <= -1) {
          // We're pushing the port with the portlist. Kinda unecessary, but I
          // don't think we're in a position to strip away any data right now.
          allPorts.push(port);
        }
      });
    });

    allPorts.forEach((port) => {
      // After all the data's been accounted for:

      // 1. Get the total cars for each port only for vessels that have an ETA
      // higher than the current date.
      const futureVessels = port.vessels.filter(
        (vessel) => vessel.arrivalDate > Date.now()
      );
      const futureVesselsCars = futureVessels.reduce(
        (acc, currentValue) => acc + currentValue.cars.total,
        0
      );
      port.totalCars = futureVesselsCars;

      // 2. Sort the vessels by their arrival date
      port.vessels.sort((a, b) => a.arrivalDate - b.arrivalDate);
    });

    // This is an interesting hack: we receive a port list from the API, and
    // each vessel has its own port list as well. We can't connect the two very
    // easily since the `port.id` in the `vessel.ports` is always 0.
    // But we need to cross-reference that information so that the components
    // know which vessels have navigated through which ports.
    // The problem arises when we consider that there are some ports that won't
    // be visited by the current vessel list, right?
    // What we can do is go through each port returned by the API and
    // cross-reference that with the `vessel.ports` information we already have.
    // Crazy (but it works).
    ports.forEach((port) => {
      const formattedPortIndex = allPorts.findIndex(
        (x) => x.portName === port.name
      );

      if (formattedPortIndex > -1) {
        const formattedPort = allPorts[formattedPortIndex];
        formattedPort.id = port.id;
        allPorts[formattedPortIndex] = formattedPort;
      } else if (InoperativePorts.indexOf(port.name) <= -1) {
        // If the port doesn't exist, it means that there are no vessels
        // visiting it (or that have visited it in the past). We must still
        // account for them, though.

        const additionalPort = {
          id: port.id,
          portName: port.name,
          vessels: [],
          center: {
            lat: parseFloat(port.location[0]),
            lng: parseFloat(port.location[1]),
          },
          cars: formatCars.cargoTotal(port.cars),
        };

        allPorts.push(additionalPort);
      }
    });

    // Finally, we sort the ports by their first vessels' arrival date
    allPorts.sort((firstPort, secondPort) => {
      if (firstPort.vessels.length <= 0 || secondPort.vessels.length <= 0)
        return -1;

      const lhsVessel = firstPort.vessels[0];
      const rhsVessel = secondPort.vessels[0];
      return rhsVessel.departureDate - lhsVessel.departureDate;
    });

    return { formattedPorts: allPorts, formattedVessels: allVessels };
  },
};

export const formatFactories = {
  fromResponse: (factories, ports) => {
    if (!factories || !ports) return;

    // Get the data from the factories array and sort them by name
    const mappedFactories = factories.map((factory) => ({
      id: factory.id,
      name: factory.name,
      cars: formatCars.cargoTotal(factory.cars),
      type: ObjectType.FACTORY,
      center: {
        lat: parseFloat(factory.location[0]),
        lng: parseFloat(factory.location[1]),
      },
    }));
    mappedFactories.sort((a, b) => a.name.localeCompare(b.name));

    // Emden must also be displayed in the “Not Departed” tab
    const emdenPort = ports.find((x) => x.name === EmdenPort.name);
    if (!emdenPort) return mappedFactories;

    mappedFactories.unshift({
      id: emdenPort.id,
      name: EmdenPort.name,
      cars: formatCars.cargoTotal(emdenPort.cars),
      type: ObjectType.PORT,
      center: {
        lat: parseFloat(emdenPort.location[0]),
        lng: parseFloat(emdenPort.location[1]),
      },
    });

    return mappedFactories;
  },
};

const formatCars = {
  cargoTotal: (cargo) => {
    if (!cargo) return { total: 0, breakdown: [] };

    let total = 0;
    let breakdown = [];

    const keys = Object.keys(cargo);

    keys.forEach((x) => {
      const value = cargo[x];

      // A "value" can be: a string, an object with the `total` only, or an
      // object with the full breakdown.
      const shouldBreakdownValue = value.total;
      const valueKeys = Object.keys(value);

      const individualCarTotal = parseInt(
        (shouldBreakdownValue && value.total) || value || 0
      );

      let detailed = [];

      // If there are more than one key (more than the "total"), populate the
      // `detailed` array.
      if (shouldBreakdownValue && valueKeys.length > 1) {
        valueKeys.forEach((key) => {
          if (key !== "total") {
            detailed.push({ identifier: key, value: value[key] });
          }
        });
      }

      // The API is not sending us the Panamera naming as expected, so we have
      // to work around it. If “x” (the key) is empty, consider it to be a
      // Panamera.
      const idealCargoModel = x.length > 0 ? x : PoscheModel.Panamera;

      total += individualCarTotal;
      breakdown.push({
        model: idealCargoModel,
        total: individualCarTotal,
        detailed,
      });
    });

    // Sort the items with the ideal order given by PCNA.
    breakdown.sort(
      (a, b) =>
        PorscheModelOrder.indexOf(a.model) - PorscheModelOrder.indexOf(b.model)
    );

    return {
      total,
      breakdown,
    };
  },
};

export const formatDate = {
  shortDate: (date) => {
    const formatter = new Intl.DateTimeFormat([], {
      day: "2-digit",
      month: "2-digit",
    });

    return formatter.format(date);
  },
  shortDateAndTime: (date) => {
    const formatter = new Intl.DateTimeFormat([], {
      day: "2-digit",
      month: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    });

    return formatter.format(date);
  },
};

export const formatNumber = {
  default: (amount) => {
    const formatter = new Intl.NumberFormat();
    return formatter.format(amount);
  },
};

export const formatPlotting = {
  fromPorts: (ports) => {
    if (!ports || ports.length <= 0) return [];

    const validPorts = ports.filter((x) => x && x.center);

    const portPlots = validPorts.map((port) => ({
      options: {
        position: port.center,
        label: { text: port.portName },
      },
      object: { type: ObjectType.PORT, identifier: port.id },
      plotType: PlotType.MARKER,
    }));

    return portPlots;
  },
  fromFactories: (factories) => {
    if (!factories || factories.length <= 0) return [];

    const validFactories = factories.filter((x) => x && x.center);

    const factoryPlots = validFactories.map((factory) => ({
      options: {
        position: factory.center,
        label: { text: factory.name },
      },
      // We can return the whole factory object here, but just to keep  it
      // consistent and help  with debugging, we're returning the object as it
      // should be: just a `type` and an `identifier`.
      object: { type: factory.type, identifier: factory.id },
      plotType: PlotType.MARKER,
    }));

    return factoryPlots;
  },
  fromVessels: (vessels) => {
    if (!vessels || vessels.length <= 0) return [];

    let vesselPlots = [];

    vessels.forEach((vessel) => {
      // -----------------------------------------------------------------------
      // Projected -------------------------------------------------------------
      const vesselProjectPath = vessel.projected
        ? vessel.projected.map((projected) => ({
            lat: parseFloat(projected[0]),
            lng: parseFloat(projected[1]),
          }))
        : [];

      vesselPlots.push({
        options: {
          path: vesselProjectPath,
          type: PolylineType.DASHED,
        },
        object: { type: ObjectType.VESSEL, identifier: vessel.imo },
        plotType: PlotType.POLYLINE,
      });

      // -----------------------------------------------------------------------
      // Tracks ----------------------------------------------------------------
      const vesselTrackPath = vessel.tracks
        ? vessel.tracks.map((track) => ({
            lat: parseFloat(track[0]),
            lng: parseFloat(track[1]),
          }))
        : [];

      vesselPlots.push({
        options: {
          path: vesselTrackPath,
          type: PolylineType.SOLID,
          zIndex: 9,
        },
        object: { type: ObjectType.VESSEL, identifier: vessel.imo },
        plotType: PlotType.POLYLINE,
      });

      // -----------------------------------------------------------------------
      // Marker ----------------------------------------------------------------
      vesselPlots.push({
        options: {
          position: {
            lat: parseFloat(vessel.location[0]),
            lng: parseFloat(vessel.location[1]),
          },
          label: { text: vessel.name },
          zIndex: window.google.maps.Marker.MAX_ZINDEX,
        },
        object: { type: ObjectType.VESSEL, identifier: vessel.imo },
        plotType: PlotType.MARKER,
      });
    });

    return vesselPlots;
  },
};
