//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from "axios";
//------------------------------------------------------------------------------
// Interceptors ----------------------------------------------------------------
import {
  addAuthorizationInterceptor,
  addUnauthorizedInterceptor,
} from "@api/interceptors";
//------------------------------------------------------------------------------
// Client ----------------------------------------------------------------------
const client = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

addAuthorizationInterceptor(client);
addUnauthorizedInterceptor(client);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default client;
