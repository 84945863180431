//------------------------------------------------------------------------------
// Axios -----------------------------------------------------------------------
import axiosRetry from "axios-retry";
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { getValue } from "@helpers/localStorage";
import { UserStorageKey } from "@constants/localStorage";
import { StatusCode } from "@constants/api";
//------------------------------------------------------------------------------
// Interceptors ----------------------------------------------------------------
function addAuthorizationInterceptor(client) {
  const authorizationMiddleware = (req) => {
    const storedUser = getValue(UserStorageKey, true);

    if (storedUser && Object.keys(storedUser).length > 0) {
      req.headers.common["Authorization"] = storedUser.token;
    }

    return req;
  };

  client.interceptors.request.use(authorizationMiddleware, Promise.reject);
}

function addUnauthorizedInterceptor(client) {
  client.interceptors.response.use(
    (res) => Promise.resolve(res),
    async (err) => {
      // const { response: { status } = {} } = err || {};
      // const originalRequest = err.config;
      //
      // const storedUser = getValue(UserStorageKey);
      // const hasStoredUser = storedUser && Object.keys(storedUser).length > 0;
      // const isUnauthorized =
      //   status === StatusCode.Unauthorized || status === StatusCode.Forbidden;
      //
      // // If the refresh request was unauthorized, deauth user
      // if (
      //   isUnauthorized &&
      //   originalRequest.url.indexOf('/authenticate/refresh') > -1
      // )
      //   return deauth();
      //
      // // If there is a stored user and the request was unauthorized...
      // if (hasStoredUser && isUnauthorized) {
      //   if (originalRequest.hasRefreshed) {
      //     // If we've already tried refreshing the token and the request is still
      //     // being marked as unauthorized, log the user out
      //     // return deauth();
      //     return;
      //   } else {
      //     // Otherwise, mark the request as refreshed
      //     originalRequest.hasRefreshed = true;
      //
      //     return new Promise((resolve, reject) => {
      //       refresh()
      //         .then(({ data }) => {
      //           const { jwtToken } = data;
      //           if (!jwtToken) return; // deauth?
      //
      //           // Store the data ignoring the redirect
      //           auth(data, true);
      //
      //           // Update the original request's Authorization header
      //           originalRequest.headers['Authorization'] = `Bearer ${jwtToken}`;
      //
      //           // And resolve the Promise with the original request, calling it
      //           // a second time.
      //           resolve(client(originalRequest));
      //         })
      //         .catch(reject);
      //     });
      //   }
      // }

      return Promise.reject(err);
    }
  );
}

function addRetryInterceptor(client) {
  axiosRetry(client, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (err) => {
      const { response: { status } = {} } = err || {};
      const isUnauthorized =
        status === StatusCode.Unauthorized || status === StatusCode.Forbidden;

      return !isUnauthorized;
    },
  });
}

export {
  addAuthorizationInterceptor,
  addUnauthorizedInterceptor,
  addRetryInterceptor,
};
