// ¯\_(ツ)_/¯

import {
  BeniciaPortImage,
  DavisvillePortImage,
  EmdenPortImage,
  HoustonPortImage,
  JacksonvillePortImage,
  SanDiegoPortImage,
  LeipzigPortImage,
} from "@assets/img/ports";

import {
  BratislavaFactoryImage,
  OsnabruckFactoryImage,
  StuttgartFactoryImage,
} from "@assets/img/factories";

export const StaticAsset = {
  Factory: (factory) => {
    switch (factory.name) {
      case "Bratislava":
        return BratislavaFactoryImage;

      case "Leipzig":
        return LeipzigPortImage;

      case "Osnabrueck":
        return OsnabruckFactoryImage;

      case "Zuffenhausen":
        return StuttgartFactoryImage;

      default:
        return StuttgartFactoryImage;
    }
  },
  Port: (port) => {
    switch (port.portName) {
      case "Benicia, CA":
        return BeniciaPortImage;
      case "San Diego, CA":
        return SanDiegoPortImage;
      case "Houston, TX":
        return HoustonPortImage;
      case "Jacksonville, FL":
        return JacksonvillePortImage;
      case "Davisville, RI":
        return DavisvillePortImage;
      case "EMDEN":
        return EmdenPortImage;
      default:
        return "https://www.cps.org.uk/files/imagelibrary/iStock_66282629_MEDIUM.jpg";
    }
  },
};
