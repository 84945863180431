//------------------------------------------------------------------------------
// hoc/queryParams -------------------------------------------------------------
// A query params high-order component that can also block pages from being
// accessed without a specific search param.
// Usage:
//  1. withQueryParams([{ key: 'token', required: true }], Component)
//  2. const { __searchParams: { token } } = this.props;
//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
function withQueryParams(params, WrappedComponent) {
  class WithQueryParams extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        parsed: {},
      };
    }

    componentDidMount() {
      let that = this;
      const { location } = window;

      that.parseSearchParams(location);
    }

    parseSearchParams(location) {
      const searchParams = new URLSearchParams(location.search);
      let parsed = {};

      for (let i = 0; i < params.length; i++) {
        let searchParam = params[i];
        let value = searchParams.get(searchParam.key);

        if (searchParam.required && (!value || value.length <= 0)) {
          // window.location = '/';
          break;
        }

        if (
          searchParam.exceptPaths &&
          searchParam.exceptPaths.indexOf(window.location.pathname) > -1
        ) {
          break;
        }

        parsed[searchParam.key] = value;
      }

      this.setState({ parsed });
    }

    render() {
      const { parsed } = this.state;

      return <WrappedComponent {...this.props} __searchParams={parsed} />;
    }
  }

  WithQueryParams.displayName = `WithQueryParams(${getDisplayName(
    WrappedComponent
  )})`;

  return WithQueryParams;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withQueryParams;
