//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet-async";
import isEqual from "react-fast-compare";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { ObjectType } from "@constants/object";
import { gracefullyFallback, removeFallback } from "@helpers/fallback";
import { formatPlotting } from "@helpers/formatter";
import { isEqual as isObjectEqual } from "@helpers/object";
import {
  mapInstance,
  plotInstance,
  updatePlot,
  ApiKey as GoogleAPIKey,
  PlotType,
} from "@helpers/map";
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import PlotBounds from "@/classes/plotBounds";
//------------------------------------------------------------------------------
// Internal Constants ----------------------------------------------------------
const FallbackIdentifier = {
  GOOGLE_MAPS: "GoogleFallbackIdentifier",
  VESSELS: "VesselsFallbackIdentifier",
  PORTS: "PortsFallbackIdentifier",
  FACTORY: "FactoriesFallbackIdentifier",
};

export const MapKind = {
  EN_ROUTE: [ObjectType.VESSEL, ObjectType.PORT],
  STATIONARY: [ObjectType.FACTORY],
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Map extends React.Component {
  constructor(props) {
    super(props);

    this.mapData = [];
    this.mapRef = React.createRef();
    this.loadMap = this.loadMap.bind(this);
  }

  // ---------------------------------------------------------------------------
  // First, we try loading the map. `@helpers/map` actually create the instance,
  // which is then assigned to `this.map`. If the Google Maps script hasn't
  // loaded just yet, we try loading the map again in a few seconds (instead of
  // crashing the whole platform). Same for the mapRef -- although it'll most
  // definitely always be ready by `componentDidMount()`.
  componentDidMount() {
    this.loadMap();
  }

  loadMap() {
    let that = this;

    if (!window.google || !this.mapRef || !this.mapRef.current) {
      return gracefullyFallback(FallbackIdentifier.GOOGLE_MAPS, that.loadMap);
    }

    removeFallback(FallbackIdentifier.GOOGLE_MAPS);
    this.map = mapInstance(this.mapRef);
  }

  // ---------------------------------------------------------------------------
  // When the component was updated, we need to verify what specifically has
  // been changed since `componentDidUpdate()` is called for every prop or
  // internal state.
  componentDidUpdate(prevProps) {
    const {
      ports: prevPorts,
      factories: prevFactories,
      vessels: prevVessels,
      selectedObject: prevSelectedObject,
      kind: prevKind,
    } = prevProps;
    const { ports, factories, vessels, selectedObject, kind } = this.props;

    const isDifferentKind = !isEqual(prevKind, kind);

    if (isDifferentKind || !isEqual(ports, prevPorts)) {
      this.updatePort(ports);
    }

    if (isDifferentKind || !isEqual(factories, prevFactories)) {
      this.updateFactories(factories);
    }

    if (isDifferentKind || !isEqual(vessels, prevVessels)) {
      this.updateVessel(vessels);
    }

    if (isDifferentKind || !isEqual(selectedObject, prevSelectedObject)) {
      this.highlightObjectIfNeeded(selectedObject);
    }

    if (isDifferentKind || (ports && !prevPorts) || (vessels && !prevVessels)) {
      this.updateBounds();
    }
  }

  updateBounds() {
    let plotBounds = new PlotBounds();

    const validPlots = this.mapData.filter(
      (x) =>
        x.plotType === PlotType.MARKER &&
        (x.object.type === ObjectType.PORT ||
          x.object.type === ObjectType.FACTORY)
    );
    validPlots.forEach((plot) => plotBounds.expandBy(plot));

    const mapBounds = plotBounds.latLngBounds();
    this.map.fitBounds(mapBounds);
  }

  // ---------------------------------------------------------------------------
  // Selected Object -----------------------------------------------------------
  highlightObjectIfNeeded(object) {
    // This is not ideal, but it works. The other approach was to filter the
    // highlighted vessels, filter the to-be highlighted vessels and update
    // them (and their instances in the mapData array) -- making it O(2n),
    // instead of O(n) as it is.
    // It's also worth pointing out that we're only updating the markers/
    // polylines that need to be affected, not making it super tough on
    // Google Maps.

    let plotBounds = new PlotBounds();
    const selectedPlotsCount = this.mapData.filter((x) =>
      isObjectEqual(x.object, object)
    ).length;

    for (let i = 0; i < this.mapData.length; i++) {
      const plot = this.mapData[i];

      if (isObjectEqual(plot.object, object)) {
        plotBounds.expandBy(plot);
        updatePlot(plot, true);
        plot.highlighted = true;
      } else {
        updatePlot(plot, false, selectedPlotsCount <= 0);
        plot.highlighted = false;
      }
    }

    if (object) {
      const currentZoom = this.map.getZoom();

      const mapBounds = plotBounds.latLngBounds();
      this.map.fitBounds(mapBounds);

      this.map.setZoom(currentZoom);
    } else {
      this.updateBounds();
    }
  }

  // ---------------------------------------------------------------------------
  // Ports ---------------------------------------------------------------------
  updatePort(ports) {
    const { kind } = this.props;
    if (kind.indexOf(ObjectType.PORT) <= -1)
      return this.removePlotWithObjectType(ObjectType.PORT);

    const plottingData = formatPlotting.fromPorts(ports);
    this.plotData(plottingData);
  }

  // ---------------------------------------------------------------------------
  // Factories -----------------------------------------------------------------
  updateFactories(factories) {
    const { kind } = this.props;
    if (kind.indexOf(ObjectType.FACTORY) <= -1)
      return this.removePlotWithObjectType(ObjectType.FACTORY);

    const plottingData = formatPlotting.fromFactories(factories);
    this.plotData(plottingData);
  }

  // ---------------------------------------------------------------------------
  // Vessels -------------------------------------------------------------------
  updateVessel(vessels) {
    const { kind } = this.props;
    if (kind.indexOf(ObjectType.VESSEL) <= -1)
      return this.removePlotWithObjectType(ObjectType.VESSEL);

    const plottingData = formatPlotting.fromVessels(vessels);
    this.plotData(plottingData);
  }

  // ---------------------------------------------------------------------------
  // General -------------------------------------------------------------------
  plotData(data) {
    if (!data || data.length <= 0) return;

    const { onSelectObject } = this.props;

    let additionalData = [];

    data.forEach((dataItem) => {
      const { object } = dataItem;

      this.removePlotWithObject(object);

      additionalData.push({
        instance: plotInstance(dataItem.plotType, {
          map: this.map,
          object: object,
          onClick: () => onSelectObject(object),
          options: dataItem.options,
        }),
        plotType: dataItem.plotType,
        options: dataItem.options,
        object: object,
      });
    });

    this.mapData = [...this.mapData, ...additionalData];
  }

  removePlotWithObject(object) {
    const existingPlotIndex = this.mapData.findIndex((plot) =>
      isObjectEqual(plot.object, object)
    );

    if (existingPlotIndex > -1) {
      const plot = this.mapData[existingPlotIndex];
      this.removePlot(plot);
      this.mapData.splice(existingPlotIndex, 1);
    }
  }

  removePlotWithObjectType(objectType) {
    const filteredPlots = this.mapData.filter(
      (x) => x.object.type === objectType
    );
    if (!filteredPlots || filteredPlots.length <= 0) return;

    filteredPlots.forEach((plot) => {
      // TO-DO: Improve performance here.
      const indexOf = this.mapData.indexOf(plot);

      if (indexOf > -1) {
        this.mapData.splice(indexOf, 1);
      }

      this.removePlot(plot);
    });
  }

  removePlot(plot) {
    if (!plot.instance) return;
    window.google.maps.event.clearInstanceListeners(plot.instance);
    plot.instance.setMap(null);
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.map, {
      [className]: className,
    });

    return (
      <div className={componentClasses} id="map" ref={this.mapRef}>
        <Helmet>
          <script
            type="text/javascript"
            charset="UTF-8"
            async={true}
            defer={true}
            src={GoogleAPIKey}
          />
        </Helmet>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Map;
