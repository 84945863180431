//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import axios from "axios";
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { StatusMessages, RequestPriority } from "@helpers/constants/api";
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { defaultClient } from "@api/client";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
const CancelToken = axios.CancelToken;
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GET extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fullRequests: {},
    };

    this.loadData = this.loadData.bind(this);
    this.loadRequest = this.loadRequest.bind(this);
  }

  componentDidMount() {
    this.setupRequests();
  }

  setupRequests() {
    const { requests } = this.props;
    console.assert(
      requests && requests.length > 0,
      "Use the GET component with at least one request -- or just render the children directly."
    );

    let fullRequests = {};

    requests.forEach(({ key, ...detail }) => {
      fullRequests[key] = {
        ...detail,
        loading: false,
        error: null,
        data: null,
        cancelSource: null,
      };
    });

    this.setState({ fullRequests }, this.loadData);
  }

  loadData() {
    const { fullRequests } = this.state;
    Object.keys(fullRequests).forEach(this.loadRequest);
  }

  loadRequest(key) {
    let that = this;

    const { onCompleteRequest } = this.props;

    const { fullRequests } = this.state;
    const request = fullRequests[key];

    console.assert(request.endpoint, "Every request should have an endpoint.");

    if (request.cancelSource) {
      request.cancelSource.cancel();
    }

    const { schedule, priority = RequestPriority.AVERAGE } = request;

    if (schedule) {
      setTimeout(() => this.loadRequest(key), schedule.every);
    }

    if (request.skip) return;
    let cancelSource = CancelToken.source();

    request.loading = true;
    request.cancelSource = cancelSource;
    request.error = null;

    fullRequests[key] = request;
    this.setState({ fullRequests });

    defaultClient
      .get(request.endpoint)
      .then((res) => {
        request.data = res.data;
      })
      .catch((err) => {
        if (!axios.isCancel(err)) {
          if (priority >= RequestPriority.HIGHEST) {
            that.loadRequest(key);
          } else {
            request.error = StatusMessages.Error.Actionable;
          }
        }
      })
      .finally(() => {
        request.loading = false;
        request.cancelSource = null;
        fullRequests[key] = request;
        that.setState({ fullRequests });
        if (onCompleteRequest) onCompleteRequest(key, request);
      });
  }

  render() {
    const { fullRequests } = this.state;
    const { children: render } = this.props;
    return render({ requests: fullRequests });
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default GET;
