//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { formatDate, formatNumber } from "@helpers/formatter";
import { ObjectType } from "@helpers/constants/object";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class VesselList extends React.Component {
  constructor(props) {
    super(props);
    this.listSectionComponent = this.listSectionComponent.bind(this);
    this.sectionHeaderComponent = this.sectionHeaderComponent.bind(this);
    this.sectionVesselComponent = this.sectionVesselComponent.bind(this);
  }

  listSectionComponent(section) {
    if (!section.vessels || section.vessels.length <= 0) return "";

    return (
      <div className={styles.list__section} key={section.portName}>
        {this.sectionHeaderComponent(section)}
        <div className={styles.section__vessels}>
          {section.vessels.map(this.sectionVesselComponent)}
        </div>
      </div>
    );
  }

  sectionHeaderComponent(port) {
    const { onSelectObject } = this.props;

    return (
      <header className={styles.section__header}>
        <span
          className={styles.header__port}
          onClick={() =>
            onSelectObject({ type: ObjectType.PORT, identifier: port.id })
          }
        >
          {port.portName}
        </span>
        <span className={styles.header__total}>
          Total Cars:{" "}
          <span className={styles.total__value}>
            {formatNumber.default(port.totalCars)}
          </span>
        </span>
      </header>
    );
  }

  sectionVesselComponent(vessel) {
    const { selectedObject, onSelectObject, vessels } = this.props;
    const detailedObjectInformation = vessels.find((x) => x.imo === vessel.imo);

    const isSelected =
      selectedObject &&
      selectedObject.type === ObjectType.VESSEL &&
      selectedObject.identifier === vessel.imo;

    const componentClasses = classNames(styles.section__vessel, {
      [styles[`section__vessel--selected`]]: isSelected,
    });

    return (
      <div
        className={componentClasses}
        onClick={() =>
          onSelectObject({ type: ObjectType.VESSEL, identifier: vessel.imo })
        }
        key={vessel.imo}
      >
        <span className={styles.vessel__name}>
          {detailedObjectInformation.name}
        </span>
        <span className={styles.vessel__date}>
          {formatDate.shortDate(vessel.arrivalDate)}
        </span>
        <span className={styles.vessel__total}>
          {formatNumber.default(vessel.cars.total)}
        </span>
      </div>
    );
  }

  render() {
    const { className, sections } = this.props;
    if (!sections) return "";

    const componentClasses = classNames(styles.vessel_list, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {sections.map(this.listSectionComponent)}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default VesselList;
