export const Model = {
  Panamera: "Panamera",
};

export const ModelOrder = [
  "911",
  "718",
  "Cayenne",
  "Panamera",
  "Macan",
  "Taycan",
];
