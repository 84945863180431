//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const LoadingSignalState = {
  NONE: "none",
  LOADING: "loading",
  DEFAULT: "default",
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class LoadingSignal extends React.Component {
  constructor(props) {
    super(props);

    this.state = { shouldIgnoreAnimation: false };
  }

  componentDidUpdate(prevProps) {
    let that = this;

    const { state: prevState } = prevProps;
    const { state } = this.props;

    if (state !== prevState) {
      this.setState({ shouldIgnoreAnimation: true });
      setTimeout(() => that.setState({ shouldIgnoreAnimation: false }), 100);
    }
  }

  render() {
    const { className, state } = this.props;
    const { shouldIgnoreAnimation } = this.state;

    const componentClasses = classNames(styles.loading_signal, {
      [styles[`loading_signal--${state}`]]: state,
      [className]: className,
    });

    // CSS is crazy sometimes
    const style = shouldIgnoreAnimation
      ? {
          animation: "none",
        }
      : {};

    return <div className={componentClasses} style={style}></div>;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default LoadingSignal;
