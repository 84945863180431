//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import { HelmetProvider } from "react-helmet-async";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import "./App.scss";
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { auth } from "@helpers/auth";
import { withAuthUser } from "@helpers/auth";
//------------------------------------------------------------------------------
// Components ------------------------------------------------------------------
import { Authenticated, Welcome } from "@cmp/client";
import { withQueryParams } from "@cmp/common/hoc";
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { token as exchangeTokens } from "@api/endpoints/post";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class App extends React.Component {
  componentDidUpdate(prevProps) {
    const { __searchParams = {} } = this.props;
    const { __searchParams: { apikey: prevKey } = {} } = prevProps;

    if (__searchParams.apikey !== prevKey) {
      this.updateUserIfNeeded();
    }
  }

  updateUserIfNeeded() {
    const { __searchParams: { apikey } = {} } = this.props;
    if (!apikey) return;

    exchangeTokens({ apikey })
      .then(({ data }) => auth(data, false))
      .catch((err) =>
        alert(
          "Something went wrong when signing in using this API key. Please try again."
        )
      );
  }

  render() {
    const { __authUser } = this.props;

    return (
      <HelmetProvider>
        {__authUser ? <Authenticated /> : <Welcome />}
      </HelmetProvider>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withQueryParams(
  [{ key: "apikey", required: false }],
  withAuthUser(App)
);
