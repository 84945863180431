//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
import { MdClose as CloseIcon } from "react-icons/md";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { VesselInfo, FactoryInfo, PortInfo } from "./components/client";
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { ObjectType } from "@constants/object";
const ObjectComponent = {
  [ObjectType.VESSEL]: VesselInfo,
  [ObjectType.FACTORY]: FactoryInfo,
  [ObjectType.PORT]: PortInfo,
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class ObjectInfo extends React.Component {
  idealObject() {
    const { selectedObject, ports, factories, vessels } = this.props;

    switch (selectedObject.type) {
      case ObjectType.VESSEL:
        return (
          vessels && vessels.find((x) => x.imo === selectedObject.identifier)
        );

      case ObjectType.PORT:
        return ports && ports.find((x) => x.id === selectedObject.identifier);

      case ObjectType.FACTORY:
        return (
          factories && factories.find((x) => x.id === selectedObject.identifier)
        );

      default:
        return null;
    }
  }

  render() {
    const {
      className,
      selectedObject,
      ports,
      vessels,
      factories,
      onClose,
    } = this.props;
    if (!selectedObject) return "";

    const componentClasses = classNames(styles.object_info, {
      [className]: className,
    });

    const ContentComponent = ObjectComponent[selectedObject.type];
    if (!ContentComponent) return "";

    return (
      <div className={componentClasses}>
        <CloseIcon
          className={styles.object_info__close_icon}
          onClick={onClose}
        />

        <ContentComponent
          className={styles.object_info__content}
          ports={ports}
          vessels={vessels}
          factories={factories}
          object={this.idealObject()}
        />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default ObjectInfo;
