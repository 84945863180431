//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Button } from "@cmp/common";
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { Logo } from "@assets/img/common";
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import { signIn as signInRequest } from "@api/endpoints/post";
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { auth } from "@helpers/auth";
import { StatusMessages } from "@helpers/constants/api";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Welcome extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      loading: false,
      error: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  headerComponent() {
    return (
      <header className={styles.welcome__header}>
        <img
          src={Logo}
          className={styles.header__logo}
          alt="Porsche Logo, where the arms of Stuttgart appear in the middle of the logo as an inescutcheon."
        />
        <h1 className={styles.header__title}>Porsche Vessel Tracker</h1>
      </header>
    );
  }

  formComponent() {
    const { email, password, loading, error } = this.state;

    return (
      <form className={styles.welcome__form} onSubmit={this.onSubmit}>
        {error && (
          <div className={styles.form__error}>
            <span>{error}</span>
          </div>
        )}
        <input
          type="email"
          placeholder="Your email"
          value={email}
          onChange={({ currentTarget: { value: email } }) =>
            this.setState({ email })
          }
          className={styles.form__input}
          required
        />
        <input
          type="password"
          placeholder="Your password"
          value={password}
          onChange={({ currentTarget: { value: password } }) =>
            this.setState({ password })
          }
          className={styles.form__input}
          required
        />
        <Button className={styles.form__submit} loading={loading}>
          Login
        </Button>
      </form>
    );
  }

  onSubmit(e) {
    const { email, password } = this.state;

    e.preventDefault();
    this.setState({ loading: true, error: null });

    signInRequest({ email, password })
      .then(({ data }) => auth(data))
      .catch(({ response: { data } = {} }) => {
        this.setState({
          email: "",
          password: "",
          error: (data && data.message) || StatusMessages.Error.Actionable,
          loading: false,
        });
      });
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.welcome__wrapper, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <div className={styles.welcome}>
          {this.headerComponent()}
          {this.formComponent()}
        </div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Welcome;
