//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class InfoSection extends React.Component {
  render() {
    const { className, children, title, large } = this.props;

    const componentClasses = classNames(styles.object_info__section, {
      [styles["object_info__section--large"]]: large,
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <div className={styles.object_info__content}>
          <span className={styles.section__title}>{title}</span>
          {children}
        </div>
        <div className={styles.object_info__border}></div>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default InfoSection;
