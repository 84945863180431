//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class CargoBreakdown extends React.Component {
  render() {
    const { className, breakdown } = this.props;
    const shouldPresentPlaceholder = !breakdown || breakdown.length <= 0;

    const componentClasses = classNames(styles.cargo_breakdown, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {shouldPresentPlaceholder && (
          <span className={styles.cargo_breakdown__placeholder}>
            No cars to show
          </span>
        )}
        {!shouldPresentPlaceholder &&
          breakdown.map((breakdownItem) => (
            <div className={styles.models__item} key={breakdownItem.model}>
              {breakdownItem.model && (
                <span className={styles.item__name}>{breakdownItem.model}</span>
              )}
              <span className={styles.item__total}>{breakdownItem.total}</span>
              {breakdownItem.detailed && breakdownItem.detailed.length > 0 && (
                <div className={styles.item__breakdown}>
                  {breakdownItem.detailed.map((detailedItem) => (
                    <span
                      className={styles.breakdown__single}
                      key={detailedItem.identifier}
                    >
                      <b>{detailedItem.identifier}</b> {detailedItem.value}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ))}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default CargoBreakdown;
