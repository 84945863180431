//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import axios from "axios";
//------------------------------------------------------------------------------
// Interceptors ----------------------------------------------------------------
import {
  addAuthorizationInterceptor,
  addUnauthorizedInterceptor,
  addRetryInterceptor,
} from "@api/interceptors";
//------------------------------------------------------------------------------
// Client ----------------------------------------------------------------------
const retryClient = axios.create({
  baseURL: process.env.API_ENDPOINT,
});

addAuthorizationInterceptor(retryClient);
addUnauthorizedInterceptor(retryClient);
addRetryInterceptor(retryClient);
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default retryClient;
