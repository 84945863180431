//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { getValue, removeAll, storeValue } from "@helpers/localStorage";
import { UserStorageKey } from "@constants/localStorage";
//------------------------------------------------------------------------------
// Exports ---------------------------------------------------------------------
export const withAuthUser = (WrappedComponent) => ({ children, ...props }) => (
  <WrappedComponent {...props} __authUser={getValue(UserStorageKey, true)}>
    {children}
  </WrappedComponent>
);

export const auth = (params = {}, skipRedirect) => {
  if (Object.keys(params).length > 0) {
    storeValue(UserStorageKey, JSON.stringify(params));
  } else {
    removeAll();
  }

  if (!skipRedirect) {
    window.location = "/";
  }
};

export const deauth = () => {
  removeAll();
  window.location = "/";
};
