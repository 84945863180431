//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
const DoubleClickMaximumOffset = 1000;
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
function withDoubleClick(WrappedComponent) {
  class WithDoubleClick extends React.Component {
    constructor(props) {
      super(props);
      this.timeSinceClick = -1;
    }

    handleClick(e) {
      e.preventDefault();

      const { onDoubleClick } = this.props;

      if (this.timeSinceClick > -1) {
        if (Date.now() - this.timeSinceClick <= DoubleClickMaximumOffset) {
          if (onDoubleClick) onDoubleClick();
        }

        this.timeSinceClick = -1;
      } else {
        this.timeSinceClick = Date.now();
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onClick={(e) => this.handleClick(e)}
        />
      );
    }
  }

  WithDoubleClick.displayName = `WithDoubleClick(${getDisplayName(
    WrappedComponent
  )})`;

  return WithDoubleClick;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withDoubleClick;
