//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Sidebar, Map, MapKind, ObjectInfo } from "./components";
import { withCycle } from "@cmp/common/hoc";
import { VesselList, FactoriesList } from "@cmp/common";
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import {
  DashboardData,
  FactoriesRequestKey,
  PortsRequestKey,
  ScheduleRequestKey,
  vehicleSearch,
} from "@api/endpoints/get";
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { formatData, formatFactories } from "@helpers/formatter";
import { vehiclesValidator } from "@helpers/validator";
import { isValid as isValidObject } from "@helpers/object";
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { ObjectType } from "@helpers/constants/object";
import { StatusMessages } from "@helpers/constants/api";
import { SearchStatusMessages } from "@helpers/constants/search";
export const SidebarOption = {
  ON_VESSEL: {
    title: "On Vessel",
    component: VesselList,
    mapKind: MapKind.EN_ROUTE,
    objectTypes: [ObjectType.VESSEL, ObjectType.PORT],
  },
  NOT_DEPARTED: {
    title: "Not Departed",
    component: FactoriesList,
    mapKind: MapKind.STATIONARY,
    objectTypes: [ObjectType.FACTORY],
  },
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Authenticated extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedObject: null,
      lastUpdatedOn: null,
      data: {},
      autoRotationCycle: {},
      sidebar: { selectedOption: SidebarOption.ON_VESSEL },
      search: { query: "", loading: false, message: null },
    };

    this.onCompleteRequest = this.onCompleteRequest.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.onChangeSearchQuery = this.onChangeSearchQuery.bind(this);
  }

  onSearch() {
    const { search } = this.state;
    if (search.loading) return;

    const { query: searchQuery = "" } = search;

    search.loading = true;
    search.message = null;
    this.setState({ search });

    let error;

    vehicleSearch({ searchQuery })
      .then(({ data }) => {
        const validateResults = vehiclesValidator.isSearchResultValid(
          searchQuery,
          data
        );

        const searchResult = validateResults.data;

        if (searchResult) {
          // Although a bit confusing at first, this code is just to check if
          // the search result matches whatever sidebar option is currently
          // selected.
          const selectedObject = {
            type: searchResult.statusFlag,
            identifier: searchResult.stationId,
          };

          if (!isValidObject(selectedObject))
            throw new Error(SearchStatusMessages.OUT_OF_SCOPE);

          const idealSelectedOptionKey = Object.keys(SidebarOption).find(
            (key) =>
              SidebarOption[key].objectTypes.findIndex(
                (x) => x === searchResult.statusFlag
              ) > -1
          );

          this.setState({
            selectedObject,
            sidebar: {
              selectedOption: idealSelectedOptionKey
                ? SidebarOption[idealSelectedOptionKey]
                : SidebarOption.ON_VESSEL,
            },
          });
        } else {
          error = validateResults.error;
        }
      })
      .catch((err) => {
        error =
          err && err.message === SearchStatusMessages.OUT_OF_SCOPE
            ? err.message
            : StatusMessages.Error.Actionable;
        this.setState({ selectedObject: null });
      })
      .finally(() => {
        search.loading = false;
        search.message = error;
        this.setState({ search });
      });
  }

  onChangeSearchQuery(searchQuery) {
    let { search } = this.state;
    search = search || {};

    search.query = searchQuery;

    // TO-DO: Cancel the search request when  the user clears the input field.
    if (searchQuery.length <= 0) {
      search.loading = false;
      search.message = null;
    }

    this.setState({ search });
  }

  onCycle() {
    const { data, autoRotationCycle } = this.state;

    const { vessels } = data || {};
    if (!vessels || vessels.length <= 0) return;

    let { currentIndex = 0 } = autoRotationCycle;

    const shouldCycleVessels = currentIndex < vessels.length;

    const selectedVessel = shouldCycleVessels ? vessels[currentIndex] : null;
    const selectedOption = shouldCycleVessels
      ? SidebarOption.ON_VESSEL
      : SidebarOption.NOT_DEPARTED;

    currentIndex = shouldCycleVessels ? currentIndex + 1 : 0;

    this.setState({
      selectedObject: selectedVessel
        ? { type: ObjectType.VESSEL, identifier: selectedVessel.imo }
        : null,
      sidebar: { selectedOption },
      autoRotationCycle: { currentIndex },
      search: {},
    });
  }

  onResetCycle() {
    this.setState({ autoRotationCycle: { currentIndex: 0 } });
  }

  onCompleteRequest(key, request) {
    if (key !== ScheduleRequestKey || !request || !request.data) return;

    const { ships } = request.data || {};

    this.setState({
      lastUpdatedOn: new Date(),
      data: { vessels: ships },
    });
  }

  render() {
    const { sidebar, selectedObject, search, lastUpdatedOn } = this.state;
    const { className } = this.props;

    const componentClasses = classNames(styles.authenticated, {
      [styles[`authenticated--open`]]: selectedObject,
      [className]: className,
    });

    return (
      <DashboardData
        stateIndicatorComponent={null}
        onCompleteRequest={this.onCompleteRequest}
      >
        {({ requests }) => {
          const factoriesRequest = requests[FactoriesRequestKey] || {};
          const factories = factoriesRequest.data;

          const portsRequest = requests[PortsRequestKey] || {};
          const ports = portsRequest.data;

          const scheduleRequest = requests[ScheduleRequestKey] || {};
          const schedule = scheduleRequest.data;

          const { formattedPorts, formattedVessels } =
            formatData.fromSchedule(schedule, ports) || {};
          const formattedFactories = formatFactories.fromResponse(
            factories,
            ports
          );

          const loadingRequests =
            scheduleRequest.loading || portsRequest.loading;
          const shouldPresentLoadingIndicator =
            loadingRequests && (!ports || !schedule);

          const shouldPresentError =
            (!scheduleRequest.loading && !formattedPorts) ||
            scheduleRequest.error;

          return (
            <div className={componentClasses}>
              <Sidebar
                options={[SidebarOption.ON_VESSEL, SidebarOption.NOT_DEPARTED]}
                onSelectOption={(selectedOption) =>
                  this.setState({
                    sidebar: { selectedOption },
                    selectedObject: null,
                    search: {},
                  })
                }
                ports={formattedPorts}
                vessels={formattedVessels}
                factories={formattedFactories}
                loading={loadingRequests}
                shouldPresentLoadingIndicator={shouldPresentLoadingIndicator}
                lastUpdatedOn={lastUpdatedOn}
                error={shouldPresentError}
                className={styles.authenticated__sidebar}
                onSelectObject={(selectedObject) =>
                  this.setState({ selectedObject, search: {} })
                }
                selectedObject={selectedObject}
                onSearch={this.onSearch}
                onChangeSearchQuery={this.onChangeSearchQuery}
                onClearSearch={() =>
                  this.setState({ selectedObject: null, search: {} })
                }
                search={search}
                {...sidebar}
              />
              <div className={styles.authenticated__content}>
                <Map
                  className={styles.authenticated__map}
                  selectedObject={selectedObject}
                  onSelectObject={(selectedObject) =>
                    this.setState({ selectedObject })
                  }
                  loading={shouldPresentLoadingIndicator}
                  error={shouldPresentError}
                  ports={formattedPorts}
                  vessels={formattedVessels}
                  factories={formattedFactories}
                  kind={sidebar.selectedOption.mapKind}
                  onSelectVessel={(selectedVessel) =>
                    this.setState({ selectedVessel, search: {} })
                  }
                />
                {selectedObject && (
                  <ObjectInfo
                    className={styles.authenticated__object_info}
                    ports={formattedPorts}
                    vessels={formattedVessels}
                    factories={formattedFactories}
                    selectedObject={selectedObject}
                    onClose={() =>
                      this.setState({ selectedObject: null, search: {} })
                    }
                  />
                )}
              </div>
            </div>
          );
        }}
      </DashboardData>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withCycle(Authenticated, {
  interruptible: true,
  delay: 1 * 60 * 1000,
});
