//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { StaticAsset } from "@helpers/assets";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { CargoBreakdown } from "@cmp/common";
import { InfoSection } from "../../common";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class FactoryInfo extends React.Component {
  detailsComponent() {
    const { object: factory } = this.props;

    return (
      <div className={styles.factory_info__details}>
        <h1 className={styles.details__name}>{factory.name}</h1>
        <div className={styles.details__extra}>
          <img
            src={StaticAsset.Factory(factory)}
            className={styles.details__image}
            alt={`${factory.name} factory`}
          />
        </div>
      </div>
    );
  }

  modelsComponent() {
    const { object: factory } = this.props;
    if (!factory) return "";

    return (
      <CargoBreakdown breakdown={factory.cars && factory.cars.breakdown} />
    );
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.factory_info, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <InfoSection title="Factory" className={styles.factory_info__section}>
          {this.detailsComponent()}
        </InfoSection>

        <InfoSection
          title="Production Breakdown"
          className={classNames(
            styles.factory_info__section,
            styles[`factory_info__section--large`]
          )}
        >
          {this.modelsComponent()}
        </InfoSection>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default FactoryInfo;
