//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
import {
  FaSpinner as SpinnerIcon,
  FaExclamationTriangle as ErrorIcon,
} from "react-icons/fa";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import {
  SegmentControl,
  Button,
  LoadingSignal,
  LoadingSignalState,
} from "@cmp/common";
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { deauth } from "@helpers/auth";
import { formatDate } from "@helpers/formatter";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldPresentUserActions: false,
    };
  }

  headerComponent() {
    const {
      options,
      search: { query: searchQuery = "", ...search },
      onSearch,
      selectedOption,
      onSelectOption,
      onChangeSearchQuery,
    } = this.props;
    const { shouldPresentUserActions } = this.state;

    return (
      <header className={styles.sidebar__header}>
        <SegmentControl
          className={styles.header__segment_control}
          options={options}
          selectedOption={selectedOption}
          onSelectOption={onSelectOption}
          onDoubleClick={() =>
            this.setState({
              shouldPresentUserActions: !shouldPresentUserActions,
            })
          }
        />
        <div className={styles.header__search}>
          <form
            className={styles.search__form}
            onSubmit={(e) => {
              e.preventDefault();
              if (searchQuery.length > 0) onSearch();
            }}
          >
            <input
              className={styles.search__input}
              placeholder="Search model comission or VIN"
              value={searchQuery}
              onChange={({ currentTarget: { value: searchQuery } }) => {
                onChangeSearchQuery(searchQuery);
              }}
            />
            <Button
              className={styles.search__button}
              disabled={searchQuery.length <= 0}
              loading={search.loading}
            >
              Search
            </Button>
          </form>
          {search.message && (
            <span className={styles.search__message}>{search.message}</span>
          )}
        </div>
      </header>
    );
  }

  footerComponent() {
    const { lastUpdatedOn, loading } = this.props;
    const { shouldPresentUserActions } = this.state;

    if (!lastUpdatedOn && !shouldPresentUserActions) return "";

    return (
      <footer className={styles.sidebar__footer}>
        <div className={styles.footer__loading_indicator}>
          <LoadingSignal
            state={
              loading ? LoadingSignalState.LOADING : LoadingSignalState.DEFAULT
            }
          />
          {lastUpdatedOn && (
            <span className={styles.loading_indicator__last_updated}>
              Last updated on: {formatDate.shortDateAndTime(lastUpdatedOn)}
            </span>
          )}
        </div>

        {shouldPresentUserActions && (
          <Button className={styles.footer__sign_out} onClick={() => deauth()}>
            Sign out
          </Button>
        )}
      </footer>
    );
  }

  contentComponent() {
    const {
      shouldPresentLoadingIndicator,
      error,
      selectedOption,
      selectedObject,
      onSelectObject,
      ports,
      vessels,
      factories,
    } = this.props;

    if (shouldPresentLoadingIndicator)
      return (
        <SpinnerIcon
          className={classNames(
            styles.sidebar__icon,
            styles[`sidebar__icon--spinning`]
          )}
        />
      );
    if (error)
      return <ErrorIcon className={classNames(styles.sidebar__icon)} />;

    const ContentComponent = selectedOption.component;

    return (
      <ContentComponent
        selectedObject={selectedObject}
        onSelectObject={onSelectObject}
        className={styles.sidebar__content}
        sections={ports}
        vessels={vessels}
        factories={factories}
      />
    );
  }

  render() {
    const { className, loading } = this.props;

    const componentClasses = classNames(styles.sidebar, {
      [styles[`sidebar--disabled`]]: loading,
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {this.headerComponent()}
        {this.contentComponent()}
        {this.footerComponent()}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Sidebar;
