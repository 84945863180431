//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
import { FaSpinner as SpinnerIcon } from "react-icons/fa";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class NetworkState extends React.Component {
  idealComponent() {
    const { placeholder, loading, error } = this.props;

    if (loading) {
      return <SpinnerIcon className={styles.network_state__spinner} />;
    }

    if (error) {
      const errorComponentClasses = classNames(
        styles.network_state__message,
        styles[`network_state__message--error`]
      );

      return <span className={errorComponentClasses}>{error}</span>;
    }

    if (placeholder) {
      const placeholderComponentClasses = classNames(
        styles.network_state__message,
        styles[`network_state__message--info`]
      );

      return <span className={placeholderComponentClasses}>{placeholder}</span>;
    }

    return "";
  }

  render() {
    const { className } = this.props;

    const componentClasses = classNames(styles.network_state, {
      [className]: className,
    });

    return <div className={componentClasses}>{this.idealComponent()}</div>;
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default NetworkState;
