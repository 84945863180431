//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
//------------------------------------------------------------------------------
// HOC -------------------------------------------------------------------------
function withCycle(WrappedComponent, options) {
  class WithCycle extends React.Component {
    constructor(props) {
      super(props);
      this.setupCycle = this.setupCycle.bind(this);
    }

    componentDidMount() {
      this.setupCycle();
      this.setupListeners();
    }

    componentWillUnmount() {
      this.resetCycle();
      document.removeEventListener("mousemove", () => this.setupCycle(true));
      document.removeEventListener("onkeypress", () => this.setupCycle(true));
    }

    setupListeners() {
      document.addEventListener("mousemove", () => this.setupCycle(true));
      document.addEventListener("onkeypress", () => this.setupCycle(true));
    }

    setupCycle(shouldReset) {
      let that = this;

      if (shouldReset) {
        this.resetCycle();
      }

      that.cycleTimeout = setTimeout(() => {
        if (!that.wrappedComponent || !that.wrappedComponent.onCycle)
          return console.error(
            "When using withCycle, implement the onCycle function."
          );
        that.wrappedComponent.onCycle();

        that.setupCycle();
      }, options.delay);
    }

    resetCycle() {
      if (this.cycleTimeout) {
        clearTimeout(this.cycleTimeout);
        this.cycleTimeout = null;
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          ref={(ref) => (this.wrappedComponent = ref)}
        />
      );
    }
  }

  WithCycle.displayName = `WithCycle(${getDisplayName(WrappedComponent)})`;

  return WithCycle;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withCycle;
