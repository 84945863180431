//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { withDoubleClick } from "@cmp/common/hoc";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class SegmentControl extends React.Component {
  itemComponent(option) {
    const { selectedOption, onSelectOption } = this.props;

    const componentClasses = classNames(styles.segment_control__item, {
      [styles["segment_control__item--selected"]]:
        selectedOption.title === option.title,
    });

    return (
      <div
        key={option.title}
        className={componentClasses}
        onClick={() => onSelectOption(option)}
      >
        <span className={styles.segment_control__span}>
          {option.title.toUpperCase()}
        </span>
      </div>
    );
  }

  render() {
    const { className, options, onClick } = this.props;

    const componentClasses = classNames(styles.segment_control, {
      [className]: className,
    });

    return (
      <div className={componentClasses} onClick={onClick}>
        {options && options.map((option) => this.itemComponent(option))}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withDoubleClick(SegmentControl);
