//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { formatNumber } from "@helpers/formatter";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class FactoriesList extends React.Component {
  breakdownComponent(factory) {
    const { cars: { breakdown = [] } = {} } = factory || {};
    const shouldPresentPlaceholder = !breakdown || breakdown.length <= 0;

    const componentClasses = classNames(styles.section__cars, {
      [styles[`section__cars--empty`]]: shouldPresentPlaceholder,
    });

    return (
      <div className={componentClasses}>
        {shouldPresentPlaceholder ? (
          <span className={styles.section__placeholder}>No cars to show</span>
        ) : (
          breakdown.map((car) => (
            <div className={styles.section__car} key={car.model}>
              <span className={styles.car__name}>{car.model}</span>
              <span className={styles.car__total}>
                {formatNumber.default(car.total)}
              </span>
            </div>
          ))
        )}
      </div>
    );
  }

  render() {
    const { className, factories, onSelectObject } = this.props;

    const componentClasses = classNames(styles.factories_list, {
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        {factories &&
          factories.map((factory) => (
            <div className={styles.list__section} key={factory.name}>
              <header className={styles.section__header}>
                <div className={styles.header__factories}>
                  <p
                    className={styles.factories__name}
                    onClick={() =>
                      onSelectObject({
                        type: factory.type,
                        identifier: factory.id,
                      })
                    }
                  >
                    {factory.name}
                  </p>
                </div>
                <span className={styles.header__total}>
                  Total Cars:{" "}
                  <span className={styles.total__value}>
                    {formatNumber.default(factory.cars.total)}
                  </span>
                </span>
              </header>
              {this.breakdownComponent(factory)}
            </div>
          ))}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default FactoriesList;
