let fallbacks = {};

export const gracefullyFallback = (identifier, fn) => {
  let currentAmount = fallbacks[identifier] || 1;

  setTimeout(fn, currentAmount * 1000);

  fallbacks[identifier] = ++currentAmount;
};

export const removeFallback = (identifier) => {
  // TO-DO: Store the timeout somewhere and cancel it here instead of just
  // resetting the count.
  fallbacks[identifier] = 1;
};
