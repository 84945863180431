export const StatusCode = {
  Unauthorized: 401,
  Forbidden: 403,
};

export const StatusMessages = {
  Error: {
    Generic: "Something went wrong.",
    Actionable: "Something went wrong. Please try again.",
    Support: "Something went wrong. Please contact our support team.",
  },
  Placeholder: "Nothing yet.",
  Empty: "Nothing found.",
};

// A regular integer indicating how the request should be treated by the GET
// component. At this point, the only one being actively used is HIGHEST, which
// will cause the request to run again in case it fails (instead of waiting for
// the next scheduled trigger).
// Eventually we can expand this behavior so that LOWEST, when queued, is easily
// moved to the back of the line.
export const RequestPriority = {
  HIGHEST: 10,
  AVERAGE: 5,
  LOWEST: 1,
};
