export const isEqual = (object, anotherObject) => {
  if (!object && !anotherObject) return true;
  if ((!object && anotherObject) || (object && !anotherObject)) return false;

  return (
    object.type === anotherObject.type &&
    object.identifier === anotherObject.identifier
  );
};

export const isValid = (object) => {
  if (!object || !object.type || !object.identifier) return false;
  if (object.type.length <= 0 || object.identifier.length <= 0) return false;
  return true;
};
