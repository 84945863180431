//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import ReactDOM from "react-dom";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import "@styles/common/_base.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import App from "@cmp/App";
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
