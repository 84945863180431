//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import {
  PortIcon,
  PortIconSelected,
  FactoryIcon,
  FactoryIconSelected,
  VesselIcon,
  VesselIconSelected,
} from "@assets/img/map/markers";
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { ObjectType } from "@helpers/constants/object";
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
export const Styles = [
  {
    featureType: "all",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: 36,
      },
      {
        color: "#000000",
      },
      {
        lightness: 40,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "on",
      },
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "all",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
      {
        weight: 1.2,
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 20,
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 21,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 17,
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 29,
      },
      {
        weight: 0.2,
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 18,
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 16,
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 19,
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#000000",
      },
      {
        lightness: 10,
      },
    ],
  },
];
export const ApiKey = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
export const PlotType = {
  MARKER: "MARKER",
  POLYLINE: "POLYLINE",
};
export const PolylineType = {
  DASHED: (selected, highlighting) => ({
    icons: [
      {
        icon: {
          path: "M 0,0 0,.5",
          scale: 4,
        },
        offset: "0",
        repeat: "15px",
        strokeOpacity: highlighting ? 1 : selected ? 1 : 0.2,
      },
    ],
    strokeColor: selected ? "green" : "white",
    strokeOpacity: highlighting ? 1 : selected ? 1 : 0.2,
  }),
  SOLID: (selected, highlighting) => ({
    strokeColor: selected ? "green" : "red",
    strokeOpacity: highlighting ? 1 : selected ? 1 : 0.2,
  }),
};
//------------------------------------------------------------------------------
// Functions -------------------------------------------------------------------
export const mapInstance = (mapRef) => {
  const mapComponent = mapRef && mapRef.current;
  if (!mapComponent) return;

  return new window.google.maps.Map(mapComponent, {
    zoom: 4,
    center: { lat: 37.09, lng: -95.712 },
    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
    zoomControl: true,
    mapTypeControl: true,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    scrollwheel: false,
    draggable: true,
    styles: Styles,
    restriction: {
      latLngBounds: {
        north: 85,
        south: -85,
        west: -180,
        east: 180,
      },
      strictBounds: true,
    },
  });
};

export const markerInstance = ({
  map,
  options: {
    label: { text: markerLabel, ...labelOptions },
    ...options
  },
  object,
  onClick,
}) => {
  const markerOptions = {
    label:
      markerLabel || labelOptions
        ? {
            text: markerLabel,
            fontSize: shouldScaleUp() ? "20px" : "12px",
            fontWeight: "400",
            className: `map_marker map_marker__${object.type}`,
            ...labelOptions,
          }
        : {},
    ...options,
  };

  const marker = new window.google.maps.Marker({
    map,
    draggable: false,
    cursor: "pointer",
    icon: objectIcon(object.type),
    ...markerOptions,
  });

  if (onClick) {
    marker.addListener("click", onClick);
  }

  return marker;
};

export const polylineInstance = ({
  map,
  options: { path, type },
  object,
  onClick,
}) => {
  const polyline = new window.google.maps.Polyline({
    map,
    path,
    ...type(false, true),
    clickable: true,
    strokeWeight: shouldScaleUp() ? 2 : 1,
  });

  if (onClick) {
    polyline.addListener("click", onClick);
  }

  return polyline;
};

export const plotInstance = (plotType, props) => {
  switch (plotType) {
    case PlotType.MARKER:
      return markerInstance(props);

    case PlotType.POLYLINE:
      return polylineInstance(props);

    default:
      return () => undefined;
  }
};

export const updatePlot = (plot, selected, highlighting) => {
  if (!plot || !plot.instance) return;

  switch (plot.plotType) {
    case PlotType.MARKER:
      const markerLabel = plot.instance.getLabel();

      if (markerLabel) {
        markerLabel.className = `map_marker map_marker__${plot.object.type} ${
          selected ? `map_marker__${plot.object.type}--selected` : ""
        }`;
        plot.instance.setLabel(markerLabel);
        plot.instance.setZIndex(
          window.google.maps.Marker.MAX_ZINDEX + (selected ? 1 : -1)
        );
      }

      plot.instance.setIcon(objectIcon(plot.object.type, selected));
      break;

    case PlotType.POLYLINE:
      plot.instance.setOptions(plot.options.type(selected, highlighting));
      break;

    default:
      break;
  }
};

const shouldScaleUp = () => {
  return window.innerWidth > 2500;
};

export const objectIcon = (objectType, selected) => {
  let url;

  switch (objectType) {
    case ObjectType.VESSEL:
      url = selected ? VesselIconSelected : VesselIcon;
      break;

    case ObjectType.PORT:
      url = selected ? PortIconSelected : PortIcon;
      break;

    case ObjectType.FACTORY:
      url = selected ? FactoryIconSelected : FactoryIcon;
      break;

    default:
      return null;
  }

  if (shouldScaleUp()) {
    return {
      url,
      anchor: new window.google.maps.Point(20, 20),
      origin: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(40, 40),
      labelOrigin: new window.google.maps.Point(25, -15),
    };
  } else {
    return {
      url,
      anchor: new window.google.maps.Point(15, 15),
      origin: new window.google.maps.Point(0, 0),
      scaledSize: new window.google.maps.Size(25, 25),
      labelOrigin: new window.google.maps.Point(15, -10),
    };
  }
};
