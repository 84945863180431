//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import classNames from "classnames";
//------------------------------------------------------------------------------
// Styles ----------------------------------------------------------------------
import styles from "./index.scss";
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class InfoText extends React.Component {
  render() {
    const { className, title, direction = "column", description } = this.props;

    const componentClasses = classNames(styles.object_info__text, {
      [styles[`object_info__text--${direction}`]]: direction,
      [className]: className,
    });

    return (
      <div className={componentClasses}>
        <span className={styles.object_info__title}>{title}</span>
        <span className={styles.object_info__description}>{description}</span>
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default InfoText;
