//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from "react";
import GET from "@api/methods/get";
//------------------------------------------------------------------------------
// Constants -------------------------------------------------------------------
import { RequestPriority } from "@helpers/constants/api";
export const FactoriesRequestKey = "FactoriesRequestKey";
export const PortsRequestKey = "PortsRequestKey";
export const ScheduleRequestKey = "ScheduleRequestKey";
//------------------------------------------------------------------------------
// Endpoint --------------------------------------------------------------------
const FactoriesRequest = {
  endpoint: `/facilities/factories`,
  key: FactoriesRequestKey,
};
const PortsRequest = {
  endpoint: `/facilities/ports`,
  key: PortsRequestKey,
  schedule: { every: 2 * 60 * 60 * 1000 },
};
const ScheduleRequest = {
  endpoint: `/schedule`,
  key: ScheduleRequestKey,
  schedule: { every: 5 * 60 * 1000 },
  priority: RequestPriority.HIGHEST,
};
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
export const DashboardData = ({ children, ...props }) => {
  return (
    <GET
      requests={[FactoriesRequest, PortsRequest, ScheduleRequest]}
      {...props}
    >
      {children}
    </GET>
  );
};
